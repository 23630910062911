import React from "react";
import {
  Box,
  Chip,
  Stack,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Button,
  Collapse,
  IconButton,
} from "@mui/material";

import {
  Close,
  CopyAll,
  Edit,
  ViewAgenda,
  WarningAmberRounded,
} from "@mui/icons-material";
import { LoadingProvider, LoadingContext } from "./LoadingProvider";
import {
  getCustomerInvoicePages,
  getCustomerInvoices,
  getClients,
  addInvoice,
} from "../../API/Cheques&Pos";
import { toast } from "react-toastify";

import UniversalTable from "../../../Components/common/UniversalTable";
import FloatingFormButton from "../../../Components/common/FloatingFormButton";

import { convertToBool, dateRange } from "./FilterUtility";
import FilterArea from "../../../Components/common/FilterArea";
import NumberFormat from "../../../Components/common/NumberFormater";
import CreateInvoice from "../Forms/CreateInvoice";

import { Link, Outlet } from "react-router-dom";
import DescriptionBubble from "./DescriptionBubble";
import MoreTableButton from "./MoreTableButton";
import config from "../../Config";
import CopyText from "./CopyTextInvoice";
export default function CustomerInvoices() {
  // advanced search
  const [invoice, setInvoice] = React.useState(""),
    [currency, setCurrency] = React.useState(""),
    [client, setClient] = React.useState(""),
    [orderID, setOrderID] = React.useState(""),
    [exempt, setExempt] = React.useState(""),
    [date, setDate] = React.useState("");
  //filter function
  const getFilterData = (filter) => {
    const from = new Date(date[1]);
    const to = new Date(date[2]);

    const data = filter
      ? {
          invoice,
          currency,
          client,
          orderID,
          exempt: convertToBool(exempt, "Yes", [true, false]),
          from:
            date[0] === "less" || date[0] === ""
              ? null
              : from.toLocaleString("en-GB", { timeZone: "UTC" }),
          to:
            date[0] === "greater" || date[0] === ""
              ? null
              : to.toLocaleString("en-GB", { timeZone: "UTC" }),
          currentPage: 1,
        }
      : {
          currentPage: 1,
        };

    return data;
  };

  return (
    <Box>
      <LoadingProvider
        getFilterData={getFilterData}
        getData={getCustomerInvoices}
        getPages={getCustomerInvoicePages}
      >
        <CustomerInvoiceTable
          setInvoice={setInvoice}
          invoice={invoice}
          setCurrency={setCurrency}
          currency={currency}
          setOrderID={setOrderID}
          orderID={orderID}
          setClient={setClient}
          client={client}
          setExempt={setExempt}
          exempt={exempt}
          setDate={setDate}
          date={date}
        />
      </LoadingProvider>
    </Box>
  );
}

function CustomerInvoiceTable({
  setInvoice,
  invoice,
  setCurrency,
  currency,
  setOrderID,
  orderID,
  setClient,
  client,
  setExempt,
  exempt,
  setDate,
  date,
}) {
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loadFiltered,
    loading,
    data,
    setData,
    gotAll,
    allData,
  } = React.useContext(LoadingContext);

  const PriceWithVat = ({ value }) => {
    return (
      <Stack spacing={1} direction="row" alignItems={"center"}>
        <Box>{value.currency}</Box>
        <Box>{NumberFormat(value.total.toFixed(2))}</Box>
        <Chip
          label={NumberFormat((value.total * config.VAT).toFixed(2))}
          variant={"outlined"}
        />
      </Stack>
    );
  };

  const items = [
    { id: "orderID", label: "Order ID" },
    { id: "partNumber", label: "Part Number" },
    { id: "description", label: "Description" },
    { id: "quantity", label: "Quantity" },
    {
      id: "price",
      label: "Price",
      component: (value) => NumberFormat(value.price),
    },
  ];

  const headers = [
    {
      id: "items",
      label: "",
      subRow: true,
      iconColor: "info",
      headers: items,
      subTitle: "Items",
    },
    { id: "invoiceID", label: "Invoice ID", searchable: true },
    { id: "clientName", label: "Client", searchable: true },
    {
      id: "Total",
      label: "Amount",
      searchable: true,
      component: (value) => <PriceWithVat value={value} />,
    },
    {
      id: "confirmations",
      label: "Confirmations",
      component: (value) => {
        let confText = "";

        value.orderDetails.forEach((order) =>
          order.confirmations.forEach((conf) => {
            confText += conf.type + " | " + conf.value + "; ";
          })
        );

        return <DescriptionBubble bubbleID={value.id} text={confText} />;
      },
    },
    {
      id: "inPO",
      lable: "",
      component: (value) =>
        value.inPO === 0 ? (
          <Tooltip title="Order not found in POS">
            <Box
              color={(theme) => theme.palette.warning.dark}
              sx={{
                bgcolor: "warning.main",
                px: 0.5,
                py: 0.1,
                width: "fit-content",
                borderRadius: "50%",
              }}
            >
              {value.inPo}
              <WarningAmberRounded color="inherit" />
            </Box>
          </Tooltip>
        ) : null,
    },

    {
      id: "date",
      label: "Date",
      searchable: true,
      date: true,
    },
    {
      id: "settings",
      label: "",
      component: (values) => <Settings values={values} />,
    },
  ];

  //used to clear the textfield value for the autocomple component
  const [clear, setClear] = React.useState(false);

  // advanced search functions

  const handleAdvancedSearch = () => {
    if (gotAll) {
      const filtered = allData.filter(
        (data) =>
          [data.clientID, ""].includes(client) &&
          data.currency.includes(currency) &&
          data.invoiceID.includes(invoice) &&
          [data.vatExempt, null].includes(convertToBool(exempt, "Yes")) &&
          data.items.find((item) =>
            [item.orderID.toLowerCase(), ""].includes(orderID.toLowerCase())
          ) &&
          dateRange(date[1], date[2], data.date)
      );
      setData(filtered);
      return;
    }
    loadFiltered();
  };

  const clearAdvancedSearch = () => {
    setInvoice("");
    setCurrency("");
    setOrderID("");
    setClient("");
    setExempt("");
    setDate(["", null, null]);
    setClear(true);
  };

  const [CreateInvoiceOpen, setCreateInvoiceOpen] = React.useState(false);

  const [OrderError, setOrderError] = React.useState(false);

  return (
    <>
      {/* Temporary until supplier invoices are added */}
      <Typography variant="h5" color="primary">
        Invoices
      </Typography>
      <FilterArea
        categories={[
          {
            label: "Invoice",
            type: "search",
            value: invoice,
            setValue: setInvoice,
          },
          {
            label: "Order ID",
            type: "search",
            value: orderID,
            setValue: setOrderID,
          },
          {
            label: "Client",
            type: "combo",
            value: client,
            setValue: setClient,
            getData: getClients,
            optionLabel: "name",
            valueLabel: "id",
            clear,
            setClear,
          },
          {
            label: "Vat Exempt",
            type: "select",
            options: ["Yes", "No"],
            value: exempt,
            setValue: setExempt,
          },
          {
            label: "Currency",
            type: "select",
            options: ["KES", "USD"],
            value: currency,
            setValue: setCurrency,
          },

          {
            label: "Date Written",
            type: "date",
            options: [],
            value: date,
            setValue: setDate,
          },
        ]}
        startFilter={handleAdvancedSearch}
        clearFilter={clearAdvancedSearch}
        isLoading={loading}
      />

      <UniversalTable
        headers={headers}
        data={data}
        loading={loading}
        lazyloading={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
        setLoading={loadFiltered}
        name={"Customer Invoices"}
      />

      <Button onClick={() => setOrderError(!OrderError)}>
        Orders without POS
      </Button>
      <OrderErrors data={data} open={OrderError} handleClose={setOrderError} />

      <FloatingFormButton
        title="Add New Customer Invoice"
        setOpen={setCreateInvoiceOpen}
        open={CreateInvoiceOpen}
        DialogFullScreen
      >
        <Paper elevation={1} sx={{ p: 1, pl: 2, borderRadius: 0 }}>
          <Typography variant="h5" color="primary">
            Create New Customer Invoice
          </Typography>
        </Paper>
        <CreateInvoice
          onSubmit={async (data) => {
            const response = await addInvoice(data);
            if (response.status === "success") {
              toast.success("Invoice Created Successfully");
              setCreateInvoiceOpen(false);
              loadFiltered();
            } else
              toast.error(
                "Failed to create Invoice, if the error persists contact support"
              );
            console.log("done");
          }}
        />
      </FloatingFormButton>
      <Outlet context={loadFiltered} />
    </>
  );
}

function Settings({ values }) {
  const [copyOpen, setCopyOpen] = React.useState(false);

  const handleCopyClose = () => {
    setCopyOpen(false);
  };

  return (
    <>
      <MoreTableButton id={values.id}>
        <List dense>
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to={`/invoice/summary?invoiceID=${values.id}`}
            >
              <ListItemIcon>
                <ViewAgenda />
              </ListItemIcon>
              <ListItemText primary="More Details" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setCopyOpen(true)}>
              <ListItemIcon>
                <CopyAll />
              </ListItemIcon>
              <ListItemText primary="Copy details" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to={`/invoice/edit?invoiceID=${values.id}`}
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
        </List>
      </MoreTableButton>
      <CopyText
        invoiceID={values.invoiceID}
        data={values}
        open={copyOpen}
        handleClose={handleCopyClose}
        autoCopy
      />
    </>
  );
}

function OrderErrors({ data, open, handleClose }) {
  const orderIDs = React.useMemo(() => {
    const ids = [];
    const orders = [];
    data?.forEach((invoice) => {
      if (invoice.inPO > 0) return;
      invoice.orderDetails.forEach((order) => {
        if (!ids.includes(order.id)) {
          ids.push(order.id);
          orders.push(order);
        }
      });
    });
    return orders;
  }, [data]);

  return (
    <Collapse in={open} unmountOnExit mountOnEnter>
      <Paper sx={{ p: 2, mt: 2 }}>
        <IconButton
          onClick={() => handleClose(false)}
          sx={{ float: "right", mt: -2, mr: -2 }}
        >
          <Close />
        </IconButton>
        <Typography variant="caption">Count: {orderIDs.length}</Typography>
        <List>
          {orderIDs.map((order) => (
            <ListItem key={order.id} disablePadding>
              <ListItemButton
                component={Link}
                target="_blank"
                to={`/redirect?c=order&i=${order.id}`}
              >
                <ListItemText primary={order.id} secondary={order.clientName} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Collapse>
  );
}
