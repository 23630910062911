// We use this for our list of pages that users can access
// This is used to pick what links are show in the drawer for base users
// As well as what routes are created
import React from "react";
import { Routes, Route } from "react-router-dom";
import { SessionManager } from "react-session.manager.sk";

//Icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import KeyIcon from "@mui/icons-material/Key";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import BookIcon from "@mui/icons-material/Book";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SummarizeIcon from "@mui/icons-material/Summarize";
import PaymentIcon from "@mui/icons-material/Payment";
import InventoryIcon from "@mui/icons-material/Inventory";
import AssessmentIcon from "@mui/icons-material/Assessment";

//Pages this is the component that will be rendered
import Settings from "./Pages/Main/Settings";
import Notifications from "./Pages/Main/Notifications";
import Profile from "./Pages/Main/Profile";
import Tokens from "./Pages/Main/Tokens";

import AdminDashboard from "./Pages/Admin/Dashboard";
import UserManager from "./Pages/Admin/UserManager";
import RoleManager from "./Pages/Admin/RoleManager";
import AccessControl from "./Pages/Admin/AccessControl";
import UserManagerForm from "./Components/Forms/UserManager";

// Technology Today

import Dashboard from "./TecToday/Pages/DashBoard";

// Quotes
import QuoteManager from "./TecToday/Pages/QuoteManager";
import EditQuote from "./TecToday/Components/Forms/EditQuote";
import AdminQuoteManager from "./TecToday/Pages/AdminQuoteManager";
import QuotieViewer from "./TecToday/Pages/QuoteViewer";

// Clients
import ClientManager from "./TecToday/Pages/ClientManager";
import ClientManagerForm from "./TecToday/Components/Forms/ClientManager";
import AdminClientManager from "./TecToday/Pages/AdminClientManger";
import AdminClientManagerForm from "./TecToday/Components/Forms/AdminClientManager";
import Companies from "./TecToday/Pages/Companies";
import RFQ from "./TecToday/Pages/RFQ";
import EditRfqForm from "./TecToday/Components/Forms/EditRfq";

//Orders
import Orders from "./TecToday/Pages/Orders";
import AdminOrderManager from "./TecToday/Pages/AdminOrders";
import EditForm from "./TecToday/Components/Forms/EditOrder";
import OrderStatus from "./TecToday/Components/Forms/OrderStatus";
import AdminOrderOverview from "./TecToday/Components/Forms/AdminOrderOverview";
import Logistics from "./TecToday/Pages/Logistics";
import LogisticsStatus from "./TecToday/Components/Forms/LogisticsStatus";
import Repairs from "./TecToday/Pages/Repairs";
//pos
import PosView from "./TecToday/Pages/PosView";
import Pos from "./TecToday/Pages/Pos";
import EditPos from "./TecToday/Components/Forms/EditPos";
import Suppliers from "./TecToday/Pages/Suppliers";
import EditSupplier from "./TecToday/Components/Forms/EditSupplier";

//Cheques
import ChequesManager from "./TecToday/Pages/ChequesManager";
import EditCheque from "./TecToday/Components/Forms/EditCheque";

//Invoices
// import Invoices from "./TecToday/Pages/Invoices";
import CustInvoices from "./TecToday/Components/common/CustInvoices";
// import SuppInvoices from "./TecToday/Components/common/SuppInvoices";
import EditInvoice from "./TecToday/Components/Forms/EditInvoice";
import SummaryInvoice from "./TecToday/Components/Forms/SummaryInvoice";

//Store
import StoreManager from "./TecToday/Pages/StoreManager";
import StoreForm from "./TecToday/Components/Forms/StoreEdit";

// Reports
import Reports from "./TecToday/Pages/Reports";
import {
  AccountTree,
  AddTask,
  Analytics,
  Business,
  Hardware,
  LineAxis,
  LocalShipping,
  Opacity,
  Receipt,
  ViewAgenda,
} from "@mui/icons-material";
import DeleteSupplierForm from "./TecToday/Components/Forms/DeleteSupplier";
import CompanyManager from "./TecToday/Components/Forms/CompanyManager";
import GetHighestPage from "./TecToday/Components/common/GetHighestPage";

// Rescources
import EmailSignature from "./TecToday/Pages/EmailSignature";
import AmplifyReport from "./TecToday/Pages/AmplifyReport";

// Toners
import Toners from "./TecToday/Pages/Toners";
import EditSellout from "./TecToday/Components/Toners/Forms/EditSellout";
import MoreDetails from "./TecToday/Components/Toners/Components/MoreDetails";
import OrderReport from "./TecToday/Pages/OrderReport";

// Example

/*
 {
    name: "",
    url: "",
    path: "",
    icon: ,
    element: < />,
    link: true,
  },
*/

// These routes can be accessed by anyone as long as they are logged in
export const NormalRoutes = [
  {
    name: "Dashboard",
    url: "/",
    path: "/",
    icon: DashboardIcon,
    element: <Dashboard />,
    link: true,
  },
  {
    name: "Notifications",
    url: "/notifications",
    path: "/notifications",
    icon: null,
    element: <Notifications />,
    link: false,
  },
  {
    name: "Redirect",
    url: "/redirect",
    path: "/redirect",
    icon: null,
    element: <GetHighestPage />,
    link: false,
  },
  {
    name: "Email Signature",
    url: "/genEmailSignature",
    path: "/genEmailSignature",
    icon: "null",
    element: <EmailSignature />,
    link: false,
  },
];

export const FooterRoutes = [
  {
    name: "Settings",
    url: "/settings",
    path: "/settings/*",
    icon: SettingsIcon,
    element: <Settings />,
    link: true,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "devices",
        element: <Tokens />,
      },
    ],
  },
];

// Requires specific roles to access.
// If no roles are specified no one can access them. This is to ensure the safety of the system.
export const RoleRoutes = [
  {
    name: "Quotes",
    url: "/quoteManager",
    path: "/quoteManager",
    icon: RequestQuoteIcon,
    element: <QuoteManager />,
    link: true,
    children: [{ path: "edit", element: <EditQuote /> }],
  },
  {
    name: "Clients",
    url: "/clientManager",
    path: "/clientManager",
    icon: SupervisorAccountIcon,
    element: <ClientManager />,
    link: true,
    children: [{ path: "manage", element: <ClientManagerForm /> }],
  },
  {
    name: "RFQ",
    url: "/rfqs",
    path: "/rfqs",
    icon: AddTask,
    element: <RFQ />,
    link: true,
    children: [{ path: "edit", element: <EditRfqForm /> }],
  },
  {
    name: "Orders",
    url: "/orders",
    path: "/orders",
    icon: NoteAddIcon,
    element: <Orders />,
    link: true,
  },
  {
    name: "Admin Quotes",
    url: "/adminQuoteManager",
    path: "/adminQuoteManager",
    icon: AdminPanelSettingsIcon,
    element: <AdminQuoteManager />,
    link: true,
  },
  {
    name: "Quote Viewer",
    urlL: "/quoteViewer",
    path: "/quoteViewer",
    icons: <ViewAgenda />,
    element: <QuotieViewer />,
    link: false,
  },
  {
    name: "Admin Clients",
    url: "/adminClientManager",
    path: "/adminClientManager",
    icon: AccountBoxIcon,
    element: <AdminClientManager />,
    link: true,
    children: [{ path: "manage", element: <AdminClientManagerForm /> }],
  },
  {
    name: "Companies",
    url: "/companies",
    path: "/companies",
    icon: Business,
    element: <Companies />,
    link: true,
    children: [{ path: "manage", element: <CompanyManager /> }],
  },
  {
    name: "Admin Orders",
    url: "/adminOrderManager",
    path: "/adminOrderManager",
    icon: SummarizeIcon,
    element: <AdminOrderManager />,
    link: true,
    children: [
      { path: "manage_order", element: <EditForm /> },
      { path: "manage_status", element: <OrderStatus /> },
      { path: "overview", element: <AdminOrderOverview /> },
    ],
  },
  {
    name: "View PO's",
    url: "/PosView",
    path: "/Posview",
    icon: BookIcon,
    element: <PosView />,
    link: true,
  },
  {
    name: "Pos",
    url: "/Pos",
    path: "/Pos",
    icon: BookIcon,
    element: <Pos />,
    link: true,
    children: [{ path: "edit", element: <EditPos /> }],
  },
  {
    name: "Cheques",
    url: "/cheques",
    path: "/cheques",
    icon: PaymentIcon,
    element: <ChequesManager />,
    link: true,
    children: [{ path: "edit", element: <EditCheque /> }],
  },
  {
    name: "Suppliers",
    url: "/suppliers",
    path: "/suppliers",
    icon: AccountTree,
    element: <Suppliers />,
    link: true,
    children: [
      { path: "edit", element: <EditSupplier /> },
      { path: "delete", element: <DeleteSupplierForm /> },
    ],
  },
  {
    name: "Logistics",
    url: "/logistics",
    path: "/logistics",
    icon: LocalShipping,
    element: <Logistics />,
    link: true,
    children: [{ path: "manage_status", element: <LogisticsStatus /> }],
  },
  {
    name: "Repairs",
    url: "/repairs",
    path: "/repairs",
    icon: Hardware,
    element: <Repairs />,
    link: true,
    children: [{ path: "manage_status", element: <LogisticsStatus /> }],
  },
  {
    name: "Order Report",
    url: "/orderReport",
    path: "/orderReport",
    icon: Analytics,
    element: <OrderReport />,
    link: true,
  },
  {
    name: "Toners",
    url: "/toners",
    path: "/toners",
    icon: Opacity,
    element: <Toners />,
    link: true,
    children: [
      { path: "edit", element: <EditSellout /> },
      { path: "view", element: <MoreDetails /> },
    ],
  },
  {
    name: "Invoices",
    url: "/invoice",
    path: "/invoice",
    icon: Receipt,
    element: <CustInvoices />,
    link: true,
    children: [
      //{ path: "customer", element: <CustInvoices /> },
      //{ path: "supplier", element: <SuppInvoices /> },
      { path: "edit", element: <EditInvoice /> },
      { path: "summary", element: <SummaryInvoice /> },
    ],
  },
  {
    name: "Store",
    url: "/store",
    path: "/store",
    icon: InventoryIcon,
    element: <StoreManager />,
    link: true,
    children: [{ path: "edit", element: <StoreForm /> }],
  },
  {
    name: "Amplify",
    url: "/amplify",
    path: "/amplify",
    icon: LineAxis,
    element: <AmplifyReport />,
    link: true,
  },
  {
    name: "Reports",
    url: "/reports",
    path: "/reports",
    icon: AssessmentIcon,
    element: <Reports />,
    link: true,
  },
];

export const AdminRoutes = [
  {
    name: "Admin Controls",
    url: "/admin",
    path: "/admin",
    icon: AdminPanelSettingsIcon,
    element: <AdminDashboard />,
    link: true,
  },
  {
    name: "User Manager",
    url: "/admin/users",
    path: "/admin/users/*",
    icon: SupervisorAccountIcon,
    element: <UserManager />,
    link: true,
    children: [{ path: "manage", element: <UserManagerForm /> }],
  },
  {
    name: "Role Manager",
    url: "/admin/roles",
    path: "/admin/roles",
    icon: AssignmentIndIcon,
    element: <RoleManager />,
    link: true,
  },
  {
    name: "Access Control",
    url: "/admin/access",
    path: "/admin/access",
    icon: KeyIcon,
    element: <AccessControl />,
    link: true,
  },
];

// You can ignore everything below this

// This function is used to render the routes
export function RenderRoutes() {
  const { isAdmin, userInfo } = React.useContext(SessionManager);

  const filtered = RoleRoutes.filter((val) => {
    return userInfo?.pages?.includes(val.name);
  });

  const BaseRs = NormalRoutes.concat(filtered);
  const BR = BaseRs.concat(FooterRoutes);
  const AR = AdminRoutes;

  const finalRoutes = isAdmin ? BR.concat(AR) : BR;

  return (
    <Routes>
      {finalRoutes.map((route, i) => (
        <Route key={i} path={route.path} element={route.element}>
          {route.children &&
            route.children.map((child, i) => (
              <Route
                key={`child ${i}`}
                path={child.path}
                element={child.element}
              />
            ))}
        </Route>
      ))}
      <Route path="*" element={<Dashboard />} />
    </Routes>
  );
}

export function AvailableRoutes() {
  const { isAdmin, userInfo } = React.useContext(SessionManager);

  const filtered = RoleRoutes.filter((val) => {
    return userInfo?.pages?.includes(val.name);
  });

  const BaseRs = NormalRoutes.concat(filtered);
  const BR = BaseRs.concat(FooterRoutes);
  const AR = AdminRoutes;

  const finalRoutes = isAdmin ? BR.concat(AR) : BR;

  return finalRoutes;
}
